import { useMediaQuery } from "react-responsive";
import HomePageMobile from "./HomepageMobile";
import HomePageWeb from "./HomepageWeb";
// import { useState } from "react";
// import BlackFridayModal from "../components/layout/BlackFridayModal";

const Home = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });
  // const [showBfModal, setShowBfModal] = useState(false);
  
  return (
    <div
    // style={{overflow:"hidden", position:"fixed"}}
    >
      {
        isTabletOrMobile ? <HomePageMobile /> : <HomePageWeb/>
      }
      {/* <BlackFridayModal showBfModal={showBfModal} setShowBfModal={setShowBfModal} /> */}
     
    </div>
  );
};

export default Home;
