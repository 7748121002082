// import { useDispatch } from "react-redux";
// import { userActionCreators } from "../../redux/user";
// import { bindActionCreators } from "redux";
// import { useMediaQuery } from "react-responsive";
import { Button, Input, Layout, Form, Row } from "antd";
import "../../styles/footer.css";
// import triggerNotification from "../../hooks/triggerNotification";
import MailingListModal from "../modals/MailingListModal";
import { useState } from "react";
const { Footer } = Layout;

const FooterLayout = () => {
  const [mailingListModalVisible, setMailingListModalVisible] = useState(false);

  const [subscriberEmail, setSubscriberEmail] = useState("");

  const showMailingListModal = () => {
    setMailingListModalVisible(true);
  };

  const handleMailingListModalCancel = () => {
    setMailingListModalVisible(false);
  };

  const handleMailingListModalSubmit = async (values) => {
    setSubscriberEmail(values.email);
    showMailingListModal();
    form.resetFields();
  };

  // const dispatch = useDispatch();
  const [form] = Form.useForm();
  // const { joinNewsLetter } = bindActionCreators(userActionCreators, dispatch);

  // const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });

  // const addToNewsLetter = async (values) => {
  //   const res = await joinNewsLetter(values);
  //   if (res?.status === "ok") {
  //     // message.success(res.message);
  //     triggerNotification({
  //       type: "success",
  //       message:
  //         "You have been added to our mailing list. To ensure you receive all our latest promotions and offers, save our contact information in your address book or contacts list. This way, you won't miss out on any exciting updates from us.",
  //     });
  //     return;
  //   }
  //   if (res?.status === "not ok") {
  //     // message.error(res?.message);
  //     triggerNotification({
  //       type: "error",
  //       message: "Error adding email to mailing list",
  //     });
  //     return;
  //   }
  //   triggerNotification({
  //     type: "error",
  //     message: "This action could not be completed",
  //   });
  // };

  return (
    <Footer className="footer">
      <div className="footer-links">
        <div className="contact">
          <img
            className="footer-logo"
            alt="losode"
            src="/images/logo-white-no-tag.svg"
          />

          <div className="div-contact">
            <p className="txt-phone">
              Email us:
              <a
                href="mailto:hello@losode.com"
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#fff", display: "block" }}
              >
                hello@losode.com
              </a>
            </p>

            <div className="payment-cards-container">
              <h2 className="txt-accept">We accept:</h2>
              <div className="payment">
                <img
                  alt="mastercard"
                  className="payment-icon"
                  src="/images/mastercard_inverse.svg"
                />
                <img
                  alt="jcb"
                  className="payment-icon"
                  src="/images/flutter-inverse.svg"
                />
                <img
                  alt="paypal"
                  className="payment-icon"
                  src="/images/paypal_inverse.svg"
                />
                <img
                  alt="visa"
                  className="payment-icon"
                  src="/images/visa_inverse.svg"
                />
                <img
                  alt="paystack"
                  className="payment-icon"
                  src="/images/losode_vector.svg"
                  style={{ width: "26px" }}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="help">
          <h2 className="title">Useful Information</h2>
          <div
            className="help-links"
            style={{
              color: "#6F6F6F",
              width: 400,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <a href="/about-us" className="link">
                About Us
              </a>
              <a href="/terms" className="link">
                Terms and conditions
              </a>
              <a href="/policy" className="link">
                Privacy Policy
              </a>
              <a href="/contact-us" className="link">
                Contact Us
              </a>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <a href="/delivery" className="link">
                Delivery Information
              </a>
              <a href="/returns" className="link">
                Returns Policy
              </a>
              <a href="/sell" className="link">
                Sell on Losode
              </a>
              <a href="/faqs" className="link">
                FAQs
              </a>
            </div>
          </div>
        </div>

        <div className="subscribe">
          <h2 className="title form-title">Stay in touch</h2>
          <div>
            <Form
              form={form}
              name="newsletter"
              onFinish={handleMailingListModalSubmit}
              scrollToFirstError
              layout="vertical"
              requiredMark={false}
            >
              <Row style={{ alignItems: "flex-end" }}>
                <Form.Item
                  // label="Email address"
                  name="email"
                  rules={[
                    {
                      type: "email",
                      message: "Please use an acceptable email format",
                    },
                    {
                      required: true,
                      message: "This is a required field",
                    },
                  ]}
                  style={{ marginRight: "0" }}
                >
                  <Input
                    name="email"
                    className="input-subscribe"
                    placeholder="Enter your email"
                    style={{
                      height: 40,
                      backgroundColor: "#000",
                      border: "1px solid #B7B7B7",
                      borderRadius: "0",
                      color: "#fff",
                    }}
                  />
                </Form.Item>
                <Form.Item style={{ marginLeft: "0" }}>
                  <Button
                    className="btn-subscribe"
                    htmlType="submit"
                    style={{
                      backgroundColor: "#fff",
                      color: "#000",
                      height: 40,
                      border: "1px solid #fff",
                      borderLeft: "none",
                      borderRadius: "0",
                      fontFamily: "DomaineSansText-Light",
                    }}
                  >
                    Subscribe
                  </Button>
                </Form.Item>
              </Row>
            </Form>
            <p className="keep-text">
              Keep in touch with news, promotions and offers from us
            </p>
            <div className="social-links">
              <h2 className="txt-follow">Follow us on:</h2>
              <div className="social-links-icons">
                <a
                  href="https://www.instagram.com/thisislosode/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="instagram"
                    className="social-media-icon"
                    src="/images/instagram-logo.svg"
                  />
                </a>
                <a
                  href="https://facebook.com/losode1/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="facebook"
                    className="social-media-icon"
                    src="/images/facebook-logo.svg"
                  />
                </a>
                <a
                  href="https://youtube.com/user/LosodeTV"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="youtube"
                    className="social-media-icon"
                    src="/images/youtube-logo.svg"
                  />
                </a>
                <a
                  href="https://twitter.com/thisislosode"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="twitter"
                    className="social-media-icon"
                    src="/images/x-logo.png"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/losode"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    alt="linkedin"
                    className="social-media-icon"
                    src="/images/linkedin-logo.svg"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-footer-links">
        <div className="contact">
          <div>
            <img
              className="footer-logo"
              alt="losode"
              src="/images/logo-white-no-tag.svg"
            />

            <div className="div-contact">
              <p className="txt-phone">
                Email us:
                <a
                  href="mailto:hello@losode.com"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#B5B5B5", display: "block" }}
                >
                  hello@losode.com
                </a>
              </p>
            </div>
          </div>

          <div className="subscribe">
            <h2 className="title form-title">Stay in touch</h2>
            <div>
              <Form
                form={form}
                name="newsletter"
                onFinish={handleMailingListModalSubmit}
                scrollToFirstError
                layout="vertical"
                requiredMark={false}
              >
                <Row style={{ alignItems: "flex-end" }}>
                  <Form.Item
                    // label="Email address"
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "Please use an acceptable email format",
                      },
                      {
                        required: true,
                        message: "This is a required field",
                      },
                    ]}
                    style={{ marginRight: "0" }}
                  >
                    <Input
                      name="email"
                      className="input-subscribe"
                      placeholder="Enter your email"
                      style={{
                        height: 40,
                        backgroundColor: "#000",
                        border: "1px solid #B7B7B7",
                        borderRadius: "0",
                        color: "#fff",
                      }}
                    />
                  </Form.Item>
                  <Form.Item style={{ marginLeft: "0" }}>
                    <Button
                      className="btn-subscribe"
                      htmlType="submit"
                      style={{
                        backgroundColor: "#fff",
                        color: "#000",
                        height: 40,
                        border: "1px solid #fff",
                        borderLeft: "none",
                        borderRadius: "0",
                      }}
                    >
                      Subscribe
                    </Button>
                  </Form.Item>
                </Row>
              </Form>
              <p className="keep-text">
                Keep in touch with news, promotions and offers from us
              </p>
            </div>
          </div>
        </div>

        <div className="mobile-help">
          <h2 className="title">Useful Information</h2>
          <div
            className="help-links"
            style={{
              color: "#6F6F6F",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <a href="/about-us" className="link">
                About Us
              </a>
              <a href="/terms" className="link">
                Terms and conditions
              </a>
              <a href="/policy" className="link">
                Privacy Policy
              </a>
              <a href="/contact-us" className="link">
                Contact Us
              </a>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <a href="/delivery" className="link">
                Delivery Information
              </a>
              <a href="/returns" className="link">
                Returns Policy
              </a>
              <a href="/sell" className="link">
                Sell on Losode
              </a>
              <a href="/faqs" className="link">
                FAQs
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="mobile-payment-cards-container">
        <h2 className="txt-accept">We accept:</h2>
        <div className="payment">
          <img
            alt="mastercard"
            className="payment-icon"
            src="/images/mastercard_inverse.svg"
          />
          <img
            alt="jcb"
            className="payment-icon"
            src="/images/flutter-inverse.svg"
          />
          <img
            alt="paypal"
            className="payment-icon"
            src="/images/paypal_inverse.svg"
          />
          <img
            alt="visa"
            className="payment-icon"
            src="/images/visa_inverse.svg"
          />
          <img
            alt="paystack" //rename later
            className="payment-icon paystack-icon"
            src="/images/losode_vector.svg"
          />
        </div>
      </div>
      <div className="mobile-social-links">
        <h2 className="txt-follow">Follow us on:</h2>
        <div className="social-links-icons">
          <a
            href="https://www.instagram.com/thisislosode/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt="instagram"
              className="social-media-icon"
              src="/images/instagram-logo.svg"
            />
          </a>
          <a
            href="https://facebook.com/losode1/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt="facebook"
              className="social-media-icon"
              src="/images/facebook-logo.svg"
            />
          </a>
          <a
            href="https://youtube.com/user/LosodeTV"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt="youtube"
              className="social-media-icon"
              src="/images/youtube-logo.svg"
            />
          </a>
          <a
            href="https://twitter.com/thisislosode"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt="twitter"
              className="social-media-icon"
              src="/images/x-logo.png"
            />
          </a>
          <a
            href="https://www.linkedin.com/company/losode"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              alt="linkedin"
              className="social-media-icon"
              src="/images/linkedin-logo.svg"
            />
          </a>
        </div>
      </div>
      {/* <hr className="fLine" /> */}
      <div className="info">
        <p className="fText">
          &copy; {new Date().getFullYear()}, Losode Inc.{" "}
          <span className="fText-span" style={{ color: "#6F6F6F" }}>
            Always Beyond Borders
          </span>{" "}
        </p>
        <div className="meta">
          <p className="fText">All Rights Reserved.</p>
        </div>
      </div>

      <MailingListModal
        handleCancel={handleMailingListModalCancel}
        visible={mailingListModalVisible}
        subscriberEmail={subscriberEmail}
      />
    </Footer>
  );
};

export default FooterLayout;
