import { useNavigate } from "react-router-dom";

import { Button } from "antd";

const ShopDesigner = () => {
  const navigate = useNavigate();

  const shopBrand = (slug) => {
    navigate(`/designers/${slug}`);
  };

  const brands = [
    {
      id: 1,
      imageUrl:
        "https://res.cloudinary.com/losode-ng/image/upload/f_auto/v1732096354/website-pictures-optimized/lohije-store-img_xjamvq.png",
      name: "LOHIJE",
      subtitle: "A brand with a cause",
      description:
        "Committed to seeing every Nigerian live in their extraordinary",
      action: "Shop Designer",
      slug: "lohije",
    },
    {
      id: 2,
      imageUrl:
        "https://res.cloudinary.com/losode-ng/image/upload/f_auto/v1732096354/website-pictures-optimized/soso-store-img_oxrgqe.png",
      name: "SOSO AFRICA",
      subtitle: "Elements of tradition",
      description:
        "Woven aged fabrics in select styles. Every thread unique in its own",
      action: "Shop Designer",
      slug: "soso-africa",
    },
  ];

  return (
    <div>
      <div className="shop-gender-container">
        {brands.map((brand) => (
          <div
            key={brand.id}
            className="gender-container"
            onClick={() => shopBrand(brand.slug)}
          >
            <img
              className="img-gender"
              alt={brand.name}
              src={brand.imageUrl}
              loading="lazy"
            />
            <h3 className="gender-title">{brand.name}</h3>
            <h5 className="gender-desc">{brand.subtitle}</h5>
            <h5 className="gender-desc">{brand.description}</h5>
            <Button
              className="shop-designer-cta"
              // icon={
              //   <svg
              //     width="10"
              //     height="15"
              //     viewBox="0 0 10 15"
              //     fill="none"
              //     xmlns="http://www.w3.org/2000/svg"
              //   >
              //     <path
              //       stroke="black"
              //       d="M8.98991 8.26178L2.8976 14.3541C2.47651 14.7752 1.7956 14.7752 1.379 14.3541L0.366596 13.3417C-0.0544904 12.9206 -0.0544904 12.2397 0.366596 11.8231L4.68497 7.50472L0.366596 3.18634C-0.0544904 2.76525 -0.0544904 2.08435 0.366596 1.66774L1.37452 0.646381C1.7956 0.225295 2.47651 0.225295 2.89312 0.646381L8.98543 6.7387C9.411 7.15978 9.411 7.84069 8.98991 8.26178Z"
              //       fill="white"
              //     />
              //   </svg>
              // }
            >
              {brand.action}
            </Button>
            <p className="mobile-shop-designer-cta">{brand.action}</p>
          </div>
        ))}
      </div>
      <style jsx="true">{`
        .shop-gender-container {
          display: flex;
          justify-content: space-between;
          padding: 0 100px;
          gap: 40px;
          padding-bottom: 0;
          margin: 56px 0;
          margin-bottom: 100px;
        }
        .img-gender {
          width: 100%;
          // height: 474px;
          object-fit: cover;
          object-position: top;
          // height: calc(100vh - 290px);
        }
        .gender-title {
          font: normal 400 32px DomaineSansText-Regular;
          line-height: 34px;
          margin-top: 15px;
          margin-bottom: 16px;
          text-transform: capitalize;
          font-weight: bold;
        }
        .gender-desc {
          font: normal normal 15px DomaineSansText-Light;
          line-height: 20px;
          margin-top: 6px;
          margin-bottom: 0px;
        }
        .shop-designer-cta {
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-around;
          align-items: center;
          width: 254px;
          height: 48px;
          margin-top: 24px;
          background: #000000;
          border-radius: 2px;
          color: #fff;
          font: normal normal 17px DomaineSansText-Light;
        }
        .gender-container {
          flex-basis: 50%;
        }
        .mobile-shop-designer-cta {
          display: none;
        }

        @media (max-width: 640px) {
          .shop-gender-container {
            flex-direction: column;
            padding: 0px;
            margin: 48px 0;
            gap: 30px;
            margin-bottom: 80px;
          }
          .gender-title {
            font-size: 16px;
            margin-top: 8px;
            padding: 0 16px;
          }
          .gender-desc {
            font-size: 14px;
            margin: 0;
            padding: 0 16px;
          }
          .img-gender {
            height: 429px;
          }
          .shop-designer-cta {
            width: 208px;
            margin-left: auto;
            margin-right: auto;
            height: 40px;
            background: #000000;
            border-radius: 2px;
            color: #fff;
            font: normal normal 14px DomaineSansText-Light;
            display: none;
          }
          .mobile-shop-designer-cta {
            cursor: pointer;
            font: normal 300 12px DomaineSansText-Light;
            color: #555555;
            text-decoration: underline;
            text-align: left;
            margin-top: 7px;
            display: block;
            padding: 0 16px;
          }
        }
      `}</style>
    </div>
  );
};

export default ShopDesigner;
