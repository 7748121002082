// import { DeleteOutlined, CloseOutlined } from "@ant-design/icons";
import { Button, Collapse, Select } from "antd";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { bindActionCreators } from "redux";
import { cartActionCreators } from "../redux/cart";
import formatter from "../utils/formatter";
import capitalizeStr from "../utils/capitalizeStr";
import Prompts from "../components/modals/Prompts";
// import triggerNotification from "../hooks/triggerNotification";

import AuthModal from "../components/AuthModal";
import triggerNotification from "../hooks/triggerNotification";
import { postItem } from "../redux/user/user.actions";
import { postSavedItemToLocalStorage } from "../utils/guestSavedProductsLocal";
import ProductModal from "../components/modals/ProductModal";

const { Panel } = Collapse;

const Cart = () => {
  const isTabletOrMobile = useMediaQuery({ maxWidth: 640 });
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const quantity = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  const [isInitialRender, setIsInitialRender] = useState(true);
  const [showProductModal, setShowProductModal] = useState(false);
  const [item, setItem] = useState({});
  const { items, sum } = useSelector((state) => state.cart);
  const { data } = useSelector((state) => state.currency);
  const { code, indicator, rate } = data
    ? data
    : { code: "", currency: "", rate: "" };
  const { contact, info, isAuthenticated, type } = useSelector(
    (state) => state.user
  );

  const [buyerSignInAuthModalVisible, setBuyerSignInAuthModalVisible] =
    useState(false);

  const {
    deleteProductInCart,
    // deleteStoreProductInCart,
    checkout,
    updateProductAlreadyInCart,
    changeItemsCurrency,
    deleteCartItem,
  } = bindActionCreators(cartActionCreators, dispatch);

  const deleteItem = (item) => {
    deleteProductInCart(item);
    item?.cart_id && deleteCartItem(item.cart_id);
  };

  const saveItem = async (item) => {
    const { product_id: id, slug } = item;
    if (info.token) {
      const res = await dispatch(postItem([id]));
      if (res.status === "ok") {
        triggerNotification({
          type: "success",
          message:
            "You've successfully moved this item to your Saved Items list.",
        });
        deleteProductInCart(item);
      }
    } else {
      postSavedItemToLocalStorage(slug, code);
      triggerNotification({
        type: "success",
        message:
          "You've successfully moved this item to your Saved Items list.",
      });
      deleteProductInCart(item);
    }
    // if (res.status === "Not ok")
    //   message.error("Please login to add item to your wishlist");
  };

  const shopNewIn = () => {
    if (type === "Men")
      navigate(`/new-in/men/cid=${1}&name=new-in-men-products`);

    if (type === "Women")
      navigate(`/new-in/women/cid=${2}&name=new-in-women-products`);
  };

  const getId = () => {
    if (contact.length > 0) {
      const pryAddress = contact.find((contact) => contact.status === 1);
      return pryAddress?.id;
    }
  };

  const order_items = [];
  items.map((item) => {
    const variant = item?.variety?.find(
      (variant) =>
        variant.color === item.selectedColor?.name &&
        variant.size === item.selectedSize
    );

    order_items.push({
      ordered_quantity: item.quantityWanted,
      product_id: item.product_id,
      product_name: item.name,
      product_size: item?.selectedSize,
      product_color: item?.selectedColor?.name,
      variant_sku: variant?.variant_sku || null,
    });
    return true;
  });

  const changeQuantity = (item, newQuantity) => {
    const clonedCartItems = JSON.parse(JSON.stringify(items));

    const productAlreadyInCartIndex = clonedCartItems.findIndex((cartItem) => {
      return (
        cartItem?.product_id === item?.product_id &&
        cartItem?.selectedColor?.hex?.toLowerCase() ===
          item?.selectedColor?.hex?.toLowerCase() &&
        cartItem?.selectedSize === item?.selectedSize
      );
    });

    if (productAlreadyInCartIndex !== -1 && !isNaN(newQuantity)) {
      const clonedItemAlreadyInCart = {
        ...clonedCartItems[productAlreadyInCartIndex],
      };
      clonedItemAlreadyInCart.quantityWanted = newQuantity;
      clonedItemAlreadyInCart.totalPrice =
        clonedItemAlreadyInCart.price * clonedItemAlreadyInCart.quantityWanted;

      updateProductAlreadyInCart(clonedItemAlreadyInCart);
      // triggerNotification({
      //   type:'success',
      //   message:'Product quantity updated'
      // })
    } else {
      triggerNotification({
        type: "error",
        message: "Product not in cart",
      });
    }
  };

  const calculateShippingAmount = async () => {
    if (isAuthenticated && contact?.length !== 0) {
      const existing_address_id = getId();
      const values = { existing_address_id, order_items, currency: code };
      const res = await checkout(values);

      if (res.status === "ok") navigate("/checkout");
      if (res.status === "not ok")
        triggerNotification({
          type: "error",
          message: res.message,
        });
    } else {
      navigate("/checkout", { state: { order_items } });
    }
  };

  const groupCartItems = (itemArray) => {
    if (itemArray && itemArray?.length > 0) {
      const cartItems = [...itemArray];
      const result = cartItems.reduce(function (r, a) {
        r[a?.store_info?.store_name] = r[a?.store_info?.store_name] || [];
        r[a?.store_info?.store_name].push(a);
        return r;
      }, Object.create(null));
      return result;
    } else return [];
  };

  const [groupedCartItems, setGroupedCartItems] = useState([]);

  useEffect(() => {
    if (items && items?.length > 0) {
      setGroupedCartItems(Object.entries(groupCartItems(items)));
    }
  }, [items]);

  // const deleteStoreItems = (items) => {
  //   const itemPrices = [];
  //   items[1].map((item) => itemPrices.push(item.totalPrice));
  //   const price = itemPrices.reduce((agr, a) => agr + a);
  //   deleteStoreProductInCart(items[0], price);
  // };


  useEffect(() => {
    if (isInitialRender) {
      setIsInitialRender(false);
    } else {
      changeItemsCurrency({ currency: code, items });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [code]);

  return (
    <div style={{ background: "#F9F9F9" }}>
      {items.length > 0 ? (
        <div className="cart-page-container" style={{position: "relative"}}>
          <div className="cart-page-bag-section">
            <div className="cart-page-bag-section-header">
              <div className="cart-page-bag-section-header-title">
                MY SHOPPING BAG
              </div>
              <div className="cart-page-bag-section-header-discount">
                New to Losode? Use code{" "}
                <span style={{ textDecoration: "underline" }}>NEW10</span> for
                10% off your first order
              </div>
            </div>

            <div className="cart-page-bag-section-body">
              {groupedCartItems.map((groupedItems, index) => {
                return (
                  <Collapse
                    defaultActiveKey={["1"]}
                    key={index}
                    className="cart-items-collapse"
                  >
                    <Panel
                      header={
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{ fontFamily: "DomaineSansText-Regular" }}
                          >
                            <span>{groupedItems[0]}</span>{" "}
                            <span style={{ color: "#6f6f6f" }}>{`(${
                              groupedItems[1]?.length
                            } ${
                              groupedItems[1]?.length === 1 ? "item" : "items"
                            })`}</span>
                          </div>
                          {/* <Popconfirm
                            title={`Do you want to remove all items from ${groupedItems[0]} ?`}
                            onConfirm={(e) => {
                              deleteStoreItems(groupedItems);
                              e.stopPropagation();
                              e.preventDefault();
                            }}
                            onCancel={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                            }}
                            okText="Delete"
                            icon={<p />}
                            cancelText="Cancel"
                            className="cart-bulk-delete-pop-confirm"
                          >
                            <CloseOutlined
                              style={{ fontSize: "16px" }}
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                              }}
                            />
                          </Popconfirm> */}
                        </div>
                      }
                      key="1"
                    >
                      {groupedItems[1]?.map((item, idx) => (
                        <div
                          className="cart-page-bag-section-body-item"
                          key={idx}
                          style={{
                            borderBottom:
                              idx !== groupedItems[1]?.length - 1
                                ? "1px solid #a7a7a7"
                                : "none",
                            marginBottom:
                              idx !== groupedItems[1]?.length - 1
                                ? "24px"
                                : "none",
                          }}
                        >
                          <div className="cart-page-bag-section-body-item-main">
                            <div
                              className="cart-page-bag-section-body-item-image"
                              style={{
                                backgroundImage: `url(${item.img1})`,
                              }}
                            ></div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "space-between",
                                flex: 2,
                              }}
                            >
                              <div className="cart-page-bag-section-body-item-text">
                                <div
                                  className="cart-page-bag-section-body-item-text-store-name"
                                  onClick={() => {
                                    setItem(item);
                                    setShowProductModal(true);
                                  }}
                                >
                                  {item?.store_info?.store_name}
                                </div>
                                <div
                                  style={{
                                    textTransform: "capitalize",
                                    fontFamily: "DomaineSansText-Light",
                                  }}
                                >
                                  {capitalizeStr(item?.name)}
                                </div>
                                {item.selectedColor && item.selectedSize && (
                                  <div
                                    style={{
                                      fontFamily: "DomaineSansText-Light",
                                      display: "flex",
                                      gap: "8px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        borderRight: "1px solid grey",
                                        paddingRight: "8px",
                                      }}
                                    >
                                      {item?.selectedColor?.name}
                                    </div>
                                    <div>{item?.selectedSize}</div>
                                  </div>
                                )}

                                {/* Quantity changed to select for change */}
                                <div>
                                  Qty:{" "}
                                  <Select
                                    className="quantity-select"
                                    value={item.quantityWanted}
                                    style={{ width: "80px", border: "0px" }}
                                    onChange={(value) => {
                                      value !== null &&
                                        changeQuantity(item, value);
                                    }}
                                    placement={"topRight"}
                                  >
                                    {quantity.map((q) => (
                                      <Select.Option key={q}>{q}</Select.Option>
                                    ))}
                                    {/* <Select.Option>
                                      <Prompts
                                        title="Confirm that you want to remove this item from your shopping bag"
                                        children={<span>Delete</span>}
                                        onConfirm={() => deleteItem(item)}
                                      />
                                    </Select.Option> */}
                                  </Select>
                                </div>
                              </div>
                              <div
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  width: !isTabletOrMobile ? "90%" : "100%",
                                  gap: !isTabletOrMobile ? "0px" : "16px",
                                }}
                              >
                                <div>
                                  {item.has_discount && item.oldPrice && (
                                    <div className="discount">
                                      <span className="old-price">
                                        {formatter(
                                          item.oldPrice,
                                          rate,
                                          code,
                                          indicator
                                        )}
                                      </span>
                                      <span
                                        style={{
                                          color: "#800000",
                                          fontFamily: "DomaineSansText-Regular",
                                        }}
                                      >
                                        {item.discount_percent}% OFF
                                      </span>
                                    </div>
                                  )}
                                  <div
                                    className="cart-page-bag-section-body-item-price"
                                    style={{
                                      color: `${
                                        item.has_discount && item.oldPrice
                                          ? "#800000"
                                          : "000"
                                      }`,
                                    }}
                                  >
                                    {formatter(
                                      item.totalPrice,
                                      rate,
                                      code,
                                      indicator
                                    )}
                                  </div>
                                </div>

                                <span
                                  className="span-save"
                                  onClick={() => saveItem(item)}
                                >
                                  Save for later
                                </span>

                                <Prompts
                                  title="Confirm that you want to remove this item from your shopping bag"
                                  children={
                                    <svg
                                      width="13"
                                      height="13"
                                      viewBox="0 0 13 13"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                      style={{
                                        cursor: "pointer",
                                        width: "18px",
                                        height: "18px",
                                      }}
                                    >
                                      <g clip-path="url(#clip0_23771_58484)">
                                        <path
                                          d="M1.625 3.92383H2.70833H11.375"
                                          stroke="#6F6F6F"
                                          stroke-width="0.7"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M10.2923 3.92383V10.9238C10.2923 11.189 10.1782 11.4434 9.97502 11.6309C9.77185 11.8185 9.4963 11.9238 9.20898 11.9238H3.79232C3.505 11.9238 3.22945 11.8185 3.02629 11.6309C2.82312 11.4434 2.70898 11.189 2.70898 10.9238V3.92383M4.33398 3.92383V2.92383C4.33398 2.65861 4.44812 2.40426 4.65129 2.21672C4.85445 2.02918 5.13 1.92383 5.41732 1.92383H7.58398C7.8713 1.92383 8.14685 2.02918 8.35002 2.21672C8.55318 2.40426 8.66732 2.65861 8.66732 2.92383V3.92383"
                                          stroke="#6F6F6F"
                                          stroke-width="0.7"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M5.41602 6.42383V9.42383"
                                          stroke="#6F6F6F"
                                          stroke-width="0.7"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M7.58398 6.42383V9.42383"
                                          stroke="#6F6F6F"
                                          stroke-width="0.7"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </g>
                                      <defs>
                                        <clipPath id="clip0_23771_58484">
                                          <rect
                                            width="13"
                                            height="12"
                                            fill="white"
                                            transform="translate(0 0.923828)"
                                          />
                                        </clipPath>
                                      </defs>{" "}
                                    </svg>
                                  }
                                  onConfirm={() => deleteItem(item)}
                                />
                              </div>
                            </div>
                          </div>
                          {/* <div>
              <Button
                className="cart-page-bag-section-body-item-save-btn"
                style={{color:'black', background:'white', width:'255px',height:48, marginTop:'24px'}}
                icon={
                  <img
                    alt="save"
                    src="/images/icon-wishlist.svg"
                    style={{ position:'absolute', top:'14px', left:'20px' }}
                  />
                }
                // onClick={() => saveItem(product.product_id)}
              >
                Add To Saved Items
              </Button>
              
            </div> */}
                          {/* <div
                    className="mobile-cart-page-bag-section-body-item-delete-btn"
                    onClick={() => deleteItem(item)}
                  >
                    Remove from bag
                  </div> */}
                        </div>
                      ))}
                    </Panel>
                  </Collapse>
                );
              })}
            </div>
          </div>

          <div className="cart-page-order-info-section">
            <div className="cart-page-order-info-section-title">
              Your Order Information
            </div>
            <div className="cart-page-order-info-section-body">
              <div className="cart-page-order-info-section-body-items">
                <div>Item subtotal</div>
                <div> {formatter(sum, rate, code, indicator)}</div>
              </div>
              <div className="cart-page-order-info-section-body-delivery">
                <div>Delivery</div>
                <div>--</div>
              </div>
            </div>
            <div className="cart-page-order-info-section-total">
              <div>Total</div>
              <div>--</div>
            </div>
            <Button
              style={{
                color: "white",
                background: "black",
                width: "100%",
                height: 40,
                marginTop: "24px",
                fontSize: "15px",
                fontFamily: "DomaineSansText-Light",
              }}
              onClick={() => calculateShippingAmount()}
            >
              Proceed To Checkout
            </Button>
            <div className="cart-page-order-info-section-logos-container">
              <img
                style={{ width: "60px", height: "25px", marginTop: "5px" }}
                src="/images/flutterwave.png"
                alt="flutterwave"
              />
              <img
                style={{
                  width: "100px",
                  height: "19px",
                  marginTop: "5px",
                  marginLeft: "26px",
                }}
                src="/images/paystack.png"
                alt="paystack"
              />
            </div>
          </div>
        </div>
      ) : info?.token ? (
        <div className="cart-page-no-item-supercontainer">
          <div className="cart-page-no-item-container">
            <img
              className="cart-page-no-item-bag-icon"
              src="./images/icon-cart.svg"
              alt=""
            />
            <div className="cart-page-no-item-title">
              Your Shopping Bag is Empty
            </div>
            <div className="cart-page-no-item-subtitle">
              Add items to your Shopping Bag
            </div>
            <Button
              className="cart-page-no-item-saved-button"
              onClick={() => navigate("/saved-items")}
            >
              View Saved Items
            </Button>
            <div className="cart-page-no-item-shop-link" onClick={shopNewIn}>
              Continue Shopping
            </div>
          </div>
        </div>
      ) : (
        <div className="cart-page-no-item-supercontainer">
          <div className="cart-page-no-item-container">
            <img
              className="cart-page-no-item-bag-icon"
              src="./images/icon-cart.svg"
              alt=""
            />
            <div className="cart-page-no-item-title">
              Your Shopping Bag is Empty
            </div>
            <div className="cart-page-no-item-subtitle">
              <p>Your shopping bag is currently empty.</p>
              <p className="cart-page-no-item-subtitle-info">
                Go to{" "}
                <span
                  style={{
                    fontWeight: "600",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  onClick={shopNewIn}
                >
                  New in
                </span>{" "}
                to start shopping now. You can securely checkout as a guest or
                register with Losode to track your order history
              </p>
            </div>
            <Button
              className="cart-page-no-item-saved-button"
              onClick={() => setBuyerSignInAuthModalVisible(true)}
            >
              Register / Sign in
            </Button>
            {/* <div
              className="cart-page-no-item-shop-link"
              onClick={shopNewIn}
            >
              Continue Shopping
            </div> */}
          </div>
        </div>
      )}

      <ProductModal
        setModalVisible={setShowProductModal}
        modalVisible={showProductModal}
        product={item}
      />
      <AuthModal
        authModalVisible={buyerSignInAuthModalVisible}
        setAuthModalVisible={setBuyerSignInAuthModalVisible}
        activePage="1"
        pageUserType="buyer"
      />

      {/* 
      <Prompts
        showModal={showModal}
        title="Confirm that you want to remove this item from your shopping bag!"
        onCancel={() => setShowModal(false)}
        onConfirm={() => deleteItem(selectedItem)}
      /> */}
      <style>
        {`
        .cart-page-container .old-price {
          margin-right: 10px;
          font-size: 14px;
        }
        .cart-page-bag-section-body-item-price{
          font-size: 14px;
          font-family: 'DomaineSansText-Medium';
        }
        .discount {
          margin-top: 10px;
        }
        .cart-page-container{
          font-family: 'DomaineSansText-Light';
          font-size: 14px;
          padding: 40px 60px;
          display: flex;
          gap: 40px;
          margin: auto;
          width: 100%;
          position: relative;
          justify-content: center;
        }
        .cart-page-bag-section{
          display: flex;
          flex-direction: column;
          gap: 40px;
          // max-width:900px;
          width: 60%;
        }
        .cart-page-bag-section-header{
          background:white
        }
        .cart-page-bag-section-header-title{
          font-size: 32px;
          padding: 16px 24px;
          font-weight: bold;
        }
        .cart-page-bag-section-header-subtitle{
          font-size: 13px;
          padding:0px 24px 8px;
          color:#800000
        }
        .cart-page-bag-section-header-discount{
          font-size: 13px;
          padding:8px 24px;
          background:#FFF0F0
        }
        .cart-page-bag-section-body{
          display: flex;
          flex-direction: column;
          padding: 24px;
          gap:16px;
          background: white;
        }
        .cart-page-bag-section-body-item{
          background: white;
          position: relative;
          // margin-bottom: 24px;
          padding-bottom: 24px;
          // border-bottom: 1px solid #a7a7a7;
        }
        .cart-page-bag-section-body-item-main{
          display: flex;
          gap: 16px;
        }
          .quantity-select.ant-select:not(.ant-select-customize-input) .ant-select-selector{
          border:0px;
        }
        .cart-page-bag-section-body-item-image{
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;
          height: 150px;
          width: 100px;
        }
        .cart-page-bag-section-body-item-delete-btn{
          position: absolute;
          top: 0;
          right: 0;
          font-size: 20px;
          cursor:pointer
        }
        .cart-page-bag-section-body-item-delete-btn:hover{
          color:#800000
        }
        .cart-page-bag-section-delivery{
          background: white;
          padding: 24px;
        }
        .cart-page-bag-section-delivery-title{
          font-size: 24px;
          margin-bottom: 4px;
        }
        .cart-page-order-info-section{
          background: white;
          padding: 24px 40px;
          width: 40%;
          height: fit-content;
          max-width: 540px;
          position: sticky;
          top: 40px;
        }
        .cart-page-order-info-section-title{
          font-size: 24px;
          text-align: center;
          font-family: 'DomaineSansText-Regular';
        }
        .cart-page-order-info-section-body{
          padding: 16px 0;
          border-top: 1px solid #a8a8a8;
          border-bottom: 1px solid #a8a8a8;
          margin: 24px 0 8px;
        }
        .cart-page-order-info-section-body-items, 
        .cart-page-order-info-section-body-delivery,
        .cart-page-order-info-section-total{
          display: flex;
          justify-content: space-between;
        }
        .cart-page-no-item-supercontainer{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          padding: 80px 0;
          font-family: 'DomaineSansText-Light';
        }
        .cart-page-no-item-container{
          background: white;
          padding: 80px;
          min-height: 400px;
          font-size: 18px;
          text-align: center;
          width: 70%;
        }
        .cart-page-no-item-bag-icon{
          height: 48px;
        }
        .cart-page-no-item-title{
          font-size: 38px;
          margin-top: 14px;
        }

      
        .cart-page-no-item-subtitle{
          // width: 75%;
          width: 430px;
          margin: auto;
          font-size: 16px;
          margin-bottom: 24px;
          margin-top: 8px;
        }

        .cart-page-no-item-subtitle p {
          margin: 0;
        }

        .cart-page-no-item-saved-button{
          height: 48px;
          background: black;
          color: white;
          width: 300px;
          margin-bottom: 16px;
        }
        .cart-page-no-item-shop-link{
          text-decoration: underline;
          cursor:pointer
        }
        .cart-page-bag-section-body-item-text{
          display: flex;
          gap: 4px;
          flex-direction: column;
        }
        .cart-page-bag-section-body-item-text-store-name{
          text-transform: uppercase;
          font-family: DomaineSansText-Medium;
          // font-weight: bold;
          font-size: 16px;
          cursor:pointer;
        }
        .cart-page-bag-section-body-item-text-store-name:hover {
            opacity: 0.7;
          }
        .span-save {
          font-size:14px;
          font-weight:300;
          line-height:15.52px;
          color:#272727;
          cursor:pointer;
          font-family: 'DomaineSansText-Light';
        }
        .mobile-cart-page-bag-section-body-item-delete-btn{
          display:none
        }
        .cart-items-collapse .ant-collapse-content{
          border-color:#6f6f6f5e
        }
        .cart-items-collapse{
          border-color:#6F6F6F
        }
        .cart-items-collapse .ant-collapse-header{
          background: #fff;
          border-top-left-radius: 8px !important;
          border-top-right-radius: 8px !important;
        }
        .ant-popover-message-title{
          font-family: DomaineSansText-Light;
          color:#fff !important;
        }
        .ant-popover-buttons button{
          font-family: DomaineSansText-Light;
          height:32px;
          padding: 0px 14px
        }


        @media(max-width:640px){
          .cart-page-container{
            padding:16px;
            background: white;
            flex-direction: column;
          }
          .cart-page-bag-section-header{
            border-bottom: 1px solid #a7a7a7;
          }
          .cart-page-bag-section-header-title{
            font-size:18px;
            padding: 0;
            font-family: DomaineSansText-Regular;
          }
          .cart-page-bag-section-header-subtitle{
            padding: 0 0 8px
          }
          .cart-page-bag-section-header-discount{
            padding: 0px 0px 8px;
            font-size: 11px;
            margin-top: 6px;
            background: transparent;
            color:#800000;
          }
          .cart-page-bag-section{
            gap:16px;
            width:100%
          }
          .cart-page-bag-section-body{
            padding:16px 0
          }
          .cart-page-bag-section-body-item{
            font-size:13px
          }
          .cart-page-bag-section-body-item-image{
            height: 140px;
            background-position: top
          }
          .cart-page-bag-section-body-item-text{
            gap: 8px;
          }
          .cart-page-bag-section-body-item-text-store-name{
            font-size: 14px;
          }
          .cart-page-bag-section-body-item-delete-btn{
            display:none
          }
          .mobile-cart-page-bag-section-body-item-delete-btn{
            margin-top:16px;
            color:#800000;
            font-size:12px;
            display:block
          }
          .cart-page-bag-section-delivery-title{
            font-size:18px
          }
          .cart-page-bag-section-delivery{
            padding: 0
          }
          .cart-page-order-info-section{
            width:100%;
            padding:0;
            margin-bottom:40px
          }
          .cart-page-order-info-section-title{
            font-size:18px;
            text-align:left
          }
          .cart-page-order-info-section-body{
            margin-top:16px
          }
          .cart-page-no-item-title{
            font-size: 22px;
            font-weight: 600;
          }
          .cart-page-no-item-container{
            font-size:16px;
            padding:16px
          }
          .cart-page-no-item-subtitle{
            width:100%;
            font-size: 14px;
            margin: 25px 0 30px;
          }
          .cart-page-no-item-subtitle-info {
            width: 100%;
            text-align: center;
          }
          .cart-page-no-item-saved-button{
            font-size: 16px;
            width: 100%;
          }
          .cart-page-no-item-supercontainer{
            background:white
          }
          .old-price {
            margin-right: 0px
          }  
          .discount {
            margin-top: 5px;
            display: flex;
            flex-direction: column;
            align-items: start;
          }
        }
      `}
      </style>
    </div>
  );
};
export default Cart;
