import { useNavigate } from "react-router-dom";

const ShopDesigner = () => {
  const navigate = useNavigate();

  const shopBrand = (slug) => {
    navigate(`/designers/${slug}`);
  };

  const brands = [
    {
      id: 6264,
      imageUrl:
        "https://res.cloudinary.com/losode-ng/image/upload/f_auto/v1732086115/mens-homepage-image-4_ao510x.png",
      name: "LOHIJE",
      subtitle: "A brand with a cause",
      description:
        "Committed to seeing every Nigerian live in their extraordinary",
      action: "Shop Designer",
      slug: "lohije",
    },
    {
      id: 6143,
      imageUrl:
        "https://res.cloudinary.com/losode-ng/image/upload/f_auto/v1732086114/mens-homepage-image-5_peaajn.png",
      name: "SOSO AFRICA",
      subtitle: "Elements of tradition",
      description:
        "Woven aged fabrics in select styles. Every thread unique in its own",
      action: "Shop Designer",
      slug: "soso-africa",
    },
  ];

  return (
    <div>
      <div className="shop-gender-container">
        {brands.map((brand) => (
          <div
            key={brand.id}
            className="gender-container"
            onClick={() => shopBrand(brand.slug)}
          >
            <img
              className="img-gender"
              alt={brand.name}
              src={brand.imageUrl}
              loading="lazy"
            />
            <h3 className="gender-title">{brand.name}</h3>
            <h5 className="gender-desc">{brand.subtitle}</h5>
            <h5 className="gender-desc">{brand.description}</h5>
            <p className="mobile-shop-designer-cta">{brand.action}</p>
          </div>
        ))}
      </div>
      <style jsx="true">{`
        .shop-gender-container {
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          padding: 0px;
          margin: 20px 16px;
          gap: 30px;
          margin-bottom: 60px;
        }
        .img-gender {
          width: 100%;
          // height: 474px;
          object-fit: cover;
          object-position: top;
          // height: calc(100vh - 290px);
        }
        .gender-title {
          font: normal 400 20px DomaineSansText-Black;
          line-height: 34px;
          text-transform: capitalize;
          margin: 12px 0 8px;
          padding: 0;
        }
        .gender-desc {
          font: normal normal 16px DomaineSansText-Light;
          line-height: 24px;
          margin: 0;
          padding: 0;
        }
        .gender-container {
          flex-basis: 50%;
        }
        .mobile-shop-designer-cta {
          cursor: pointer;
          font: normal 400 18px DomaineSansText-Light;
          color: #000;
          text-decoration: underline;
          text-align: left;
          margin-top: 8px;
          padding: 0;
        }
      `}</style>
    </div>
  );
};

export default ShopDesigner;
