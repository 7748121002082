import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
// import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import { ConfigProvider } from "antd";
import { pdfjs } from "react-pdf";
import { currencyActionCreators } from "./redux/currency";
import { brandsActionCreators } from "./redux/brands";
// import { cartActionCreators } from "./redux/cart";
// import { userActionCreators } from "./redux/user";
import Router from "./routes/routes";
import "./App.css";
import "./styles/global.css";
import "./styles/responsive.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import getLocation from "./utils/getLocation";
import { BrowserRouter as RouterContainer } from "react-router-dom";
import customTheme from "./styles/customTheme";
import LoadingIcon from "./components/LoadingIcon";

// ReactGA.initialize(process.env.REACT_APP_GA_KEY);
const tagManagerArgs = {
  gtmId: "GTM-KPGZ263",
};

TagManager.initialize(tagManagerArgs);
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.min.js`;

const App = () => {
  const dispatch = useDispatch();

  const { brands } = useSelector((state) => state.brands);
  const { data } = useSelector((state) => state.currency);
  // const { items } = useSelector((state) => state.cart);
  const { code } = data ? data : { code: "" };

  const { fetchCurrency } = bindActionCreators(
    currencyActionCreators,
    dispatch
  );
  const { fetchBrands } = bindActionCreators(brandsActionCreators, dispatch);
  // const { reset } = bindActionCreators(cartActionCreators, dispatch);
  // const { postItem } = bindActionCreators(userActionCreators, dispatch);

  // const cartUpdateTime = localStorage.getItem("cartUpdateTime");

  const setDefaultCurrency = async () => {
    // const code = await getLocation();
    const code = "NGN";
    fetchCurrency(code);
  };

  // const pushToSaveItems = async () => {
  //   const itemIds = items.map((item) => item?.product_id);
  //   const res = await postItem(itemIds);
  //   if (res.status === "ok") reset();
  // };

  useEffect(() => {
    // ReactGA.pageview(window.location.pathname + window.location.search);
    if (code === undefined) {
      setDefaultCurrency();
    }
    if (brands?.length < 1) {
      fetchBrands();
    }

    // const diff = Math.floor((Date.now() - cartUpdateTime) / 1000 / 60);

    // if (diff >= 60 && items.length > 0) {
    //   if (isAuthenticated) {
    //     pushToSaveItems();
    //   } else {
    //     reset();
    //   }
    // }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      <RouterContainer>
        <ConfigProvider theme={customTheme} renderEmpty={LoadingIcon}>
          <Router />
        </ConfigProvider>
      </RouterContainer>

      <style jsx="true">{`
        .App {
          // overflow: hidden;
        }
        @media (max-width: 640px) {
          .App > div:nth-child(2) {
            padding-top: 83px;
          }
        }
      `}</style>
    </div>
  );
};

export default App;
